'use client';

import { UserProvider } from '@auth0/nextjs-auth0/client';
import { AuthContextProvider } from '~/contexts/authentication';
import AuthenticationManager from '~/services/authentication/AuthenticationManager';
import ToastParent from '~/components/toast';
import { AnalyticsProvider } from '~/services/analytics';
// import { initializeDatadog } from '~/services/datadog/rum';

interface Props {
  children: JSX.Element;
}

export const LayoutProviders = ({ children }: Props) => {
  // initializeDatadog();

  return (
    <UserProvider>
      <AuthContextProvider>
        <AuthenticationManager>
          <AnalyticsProvider>
            {children}
            <ToastParent />
          </AnalyticsProvider>
        </AuthenticationManager>
      </AuthContextProvider>
    </UserProvider>
  );
};
