import { datadogRum } from '@datadog/browser-rum';


const version = '1.24.1';
export function initializeDatadog() {
  try {
    datadogRum.init({
      applicationId: 'a3cba44c-2d3e-4956-9b59-1c0196b8ae7f',
      clientToken: 'pub5bfc18034240e1cd793529ccb3ee89d9',
      site: 'us3.datadoghq.com',
      service:'tali-website',
      env: process.env.NEXT_PUBLIC_DD_ENV || 'local',
      version: version,
      sampleRate: 100,
      replaySampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      trackSessionAcrossSubdomains: true,
      useSecureSessionCookie: true,
      silentMultipleInit: true,
    });

    datadogRum.setUser({ id: '', type: 'anonymous' });
    datadogRum.setRumGlobalContext({ deviceType: 'website' });
    datadogRum.startSessionReplayRecording();
  } catch (error) {
    // Pass if we can't initialize datadog
  }
}

export function ddSetUser(id: string, type: string) {
  try{
    datadogRum.setUser({ id, type });
  }
  catch(error){
    // Pass if we can't initialize datadog
  }
}

export enum ActionType {
  press = 'Click Action',
  typing = 'Input Action',
  submit = 'Submit Action',
  trigger = 'Programmatic Trigger',
}

export enum ActionGrouping {
  scribe = 'Transcriptions',
  auth = 'Authentication',
  chat = 'Chat Window',
  medSearch = 'Med Search',
  profile = 'Profile',
  faq = 'FAQ',
}

export type DDContext = { [key: string]: unknown };

function fetchDomain() {
  try {
    const result = window.location.hostname;
    return result;
  } catch (err) {
    // Pass
  }
  // If can't parse the domain, use the hostname
  return window.location.hostname;
}

export async function addAction(actionType: ActionType, actionGroup: ActionGrouping, name: string, context?: DDContext) {
  try {
    await datadogRum.addAction(name, { ...context, actionType, actionGroup, domain: fetchDomain() });
  } catch (error) {
    // Pass if action can't be logged
  }
}

export async function addError(errorMessage: string, error: Error | unknown, context?: DDContext) {
  try {
    await datadogRum.addError(error, { ...context, domain: fetchDomain(), errorMessage });
  } catch (error) {
    // Pass if error can't be logged
  }
}