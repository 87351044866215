'use client';

import { usePathname } from 'next/navigation';

const site = 'https://tali.ai';

export const HeaderClient = () => {
  const canonicalURL = site + usePathname();
  return (
    <>
      <link rel="canonical" href={canonicalURL} key="canonical" />
      <meta property="og:url" content={canonicalURL} key="og:url" />
      {/* No script GTM */}

      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />
    </>
  );
};
