import(/* webpackMode: "eager", webpackExports: ["StyledEngineProvider"] */ "__barrel_optimize__?names=StyledEngineProvider!=!/home/runner/work/website/website/node_modules/@mui/material/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderClient"] */ "/home/runner/work/website/website/layouts/Header.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LayoutProviders"] */ "/home/runner/work/website/website/layouts/RootProviders.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/website/website/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/website/website/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"900\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/website/website/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Noto_Serif\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-nato-serif\",\"display\":\"swap\"}],\"variableName\":\"natoSerif\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/website/website/styles/globals.scss");
